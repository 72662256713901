import jQuery from "jquery";
const $ = jQuery; // ドルマークに参照を代入(慣習的な $ を使うため)


/* smooth scroll */
var hH = $('#header').height();
$('a.smooth').click(function(){
  var speed = 500;
  var href= $(this).attr("href");
  var target = $(href == "#" || href == "" ? 'html' : href);
  var position = target.offset().top - hH;
  $("html, body").animate({scrollTop:position}, speed, "swing");
  return false;
});


$(".opensub").click(function(){
  window.open(this.href,"WindowName","width=520,height=520,resizable=yes,scrollbars=yes");
  return false;
});


/*
 * IntersectionObserver
 */
document.addEventListener('DOMContentLoaded', function () {
  // Top page
  const scroll = new ScrollShow('.obs');

  // youtube
  // Code: <iframe class="youtube-lazyload" src="data:Now%2C%20Loading..." data-src="https://www.youtube.com/embed/************" width="560" height="315" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  const youtube = new ScrollShow('.youtube-lazyload', (entries, observer) => {
    entries.forEach((el) => {
      if(el.isIntersecting){
        el.target.src = el.target.dataset.src;
        observer.unobserve(el.target);
      }
    });
  });
});

class ScrollShow {
  constructor(el, callback, options) {
    this._addOptions(options);
    this._addCallback(callback);
    const observer = new IntersectionObserver(this.callback, this.options);
    const els = document.querySelectorAll(el);
    els.forEach( el => observer.observe(el));
  }

  _addOptions(options) {
    this.options = Object.assign({
      root: null,
      rootMargin: "0px",
      threshold: 0
    }, options);
  }
  _addCallback(callback) {
    if (callback) {
      this.callback = callback;
    } else {
      this.callback = (entries, observer) => {
        entries.forEach( entry => {
          if(entry.isIntersecting) {
            entry.target.classList.add('--in');
            observer.unobserve(entry.target);
          } else {
            entry.target.classList.remove('--in');
          }
        });
      };
    }
  }
}
