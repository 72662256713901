import jQuery from "jquery";
const $ = jQuery; // ドルマークに参照を代入(慣習的な $ を使うため)

/*
 * drawer
 */
$(document).on('click','.drawer-handle', function() {
  $('body').toggleClass("--draweropen");
});
$(document).on('click','#drawer a', function() {
  $('body').toggleClass("--draweropen");
});
$(document).on('click','#footer a, #header a', function() {
  if($('body').hasClass('--draweropen')){
    $('body').toggleClass("--draweropen");
  }
});
